import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../helpers/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, NavLink } from "react-router-dom";
import axios from "axios";

import { apiUrl } from "../config";
import Booknumber from "../component/Booknumber";

function FicheAdmin () {
  const [username, setUsername] = useState("");
  const [postObject, setPostObject] = useState({});
 
const [FicheExiste , setFicheExiste ] = useState(false);
  const [image, setImage] = useState("");

  const [OK, setOK] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [checked, setchecked] = useState(false);
  const [ListOfUsers, setListOfUsers] = useState([]);
const { authState } = useContext(AuthContext);


const id = authState.id;
const prof = authState.prof;


  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
      
    }
    setIdentifiant(authState.id)
  axios
      .get(`${apiUrl}/auth/basicinfo/${id}`)

      .then((response) => {
        if (response.data === null) {
          return;
        } else {
          setUsername(response.data.username);

          setImage(response.data.photo_profil)
          setOK("true")
        }
      });
      
    axios
      .get(`${apiUrl}/posts3/renseignements/${id}}`)
      .then((response) => {
       {authState.username && setPostObject(response.data[0]);}


       if(response.data.length > 0 ) {

        setFicheExiste(true)
        return;

       }else {
        setFicheExiste(false)


       }
      });


  }, [authState.username, id,OK, isSuccess, FicheExiste]);


  useEffect(() => {
  
  
    axios.get(`${apiUrl}/send/list`).then((response) => {
      setListOfUsers(response.data);
    
    });
   
  
  }, []);


  const [Formulaire, setFormulaire] = useState(true);
  const [inputphoto, setinputphoto] = useState(false);

  const [tailledufichier, settailledufichier] = useState(0);
  const [Identifiant, setIdentifiant] = useState("");
  const [file, setFile] = useState();
  const [placeholder, setplaceholder] = useState("(...)");
  const [placeholder2, setplaceholder2] = useState(
    "(...)"
  );
  const [placeholder3, setplaceholder3] = useState(
    "(...)"
  );

 
  let history = useHistory();
 

  
  
  const initialValues = {
    title: "",
    postText: "",
    lien: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1)

      .required("Vous devez entrer des données dans ce champ"),
    postText: Yup.string()
      .min(8)
      .max(60000)
      .required("Vous devez entrer des données dans ce champ"),
    lien: Yup.string()
      .notRequired("Vous pouvez poster sans insérer de lien")
      .matches(
        /((https?):\/\/)?()/ /*  /((https?):\/\/)?(www.)/, */,
        "Entrer une URL correcte sous cette forme : https://www. !"
      ),
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("renseignement", checked);

    formData.append("postText", data.postText);

    formData.append("lien", data.lien);

    formData.append("title", data.title);

    formData.append("UserId", Identifiant);

    axios.post(`${apiUrl}/posts3`, formData, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
if(response){

  setOK((OK)=> !OK)
if(!checked) {

  if (
    window.confirm("Fiche créée ! OK Pour vous rendre sur la page des fiches; Annuler pour rester sur cette page")
  ) {
  history.push(`/`)
  } else {

  }    
}
else{

  history.push(`/FicheAdmin/${authState.id}`) 
}
}



  })};

  const deletePost = (id, FicheExiste) => {
    axios
      .delete(`${apiUrl}/posts3/${id}`, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then(() => {


        if(FicheExiste) {

          setFicheExiste(true)
          return;
  
         }else {
          setFicheExiste(false)
  
  
         }
        



        setOK((OK)=> !OK)

      });
  };

  const editPost = (option) => {
    if (option === "title") {
      let newTitle = prompt("Entrer un nouveau titre:", `${postObject.title}`);

      if (newTitle === null) {
        return;
      }
    
      axios.put(
        `${apiUrl}/posts3/title`,
        
        {
          newTitle: newTitle,
          id: postObject.id,
        },
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      );

      setPostObject({ ...postObject, title: newTitle });
      return;
    }

    if (option === "lien") {
      let newLien = prompt("Disponibilité", `${postObject.lien}`);
      if (newLien === null) {
        return;
      }
      axios.put(
        `${apiUrl}/posts3/lien`,
        {
          lien: newLien,
          id: postObject.id,
        },
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      );

      setPostObject({ ...postObject, lien: newLien });
    } else {
      let newPostText = prompt(
        "Entrer un nouveau texte:",
        `${postObject.postText}`
      );
      if (newPostText === null) {
        return;
      }
      axios.put(
        `${apiUrl}/posts3/postText`,  
        {
          newText: newPostText,
          id: postObject.id,
        },
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      );

      setPostObject({ ...postObject, postText: newPostText });
    }
  };

  const fileSelected = (event) => {
    const filesize = event.target.files[0].size;

    // Variable + logique qui stocke la taille en KB
    if (filesize > 1000000) {
      console.log(filesize);
      alert("La fichier est trop volumineux il doit faire au maximum 800 KO");
      settailledufichier(filesize);
      setIsSuccess(false);
      return;
    } else {
      const file = event.target.files[0];

      setIsSuccess(true);
      setFile(file);
      settailledufichier(filesize);
      console.log(filesize);
      console.log("FICHIER OK, peut être téléchargé  ");
    }
  };

  const Submit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      const lien = authState.photo_profil;
      formData.append("image", file);

      //Je vais chercher id dans le context
      formData.append("iduser", id);

      const result = await axios.put(
        `${apiUrl}/images/photo`,
        formData,

        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
            ancienfichier: lien,
          },
        }
      );
      setIsSuccess((isSuccess) => !isSuccess);

      settailledufichier("");
      

      
      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = () => {
    setchecked(!checked);

    if (placeholder === "Ex : 05 08 09 06 04") {
      setplaceholder("(Ex. Concourt...)");
    } else {
      setplaceholder("Ex : 05 08 09 06 04");
    }

    if (placeholder2 === "Votre Adresse") {
      setplaceholder2("(Ex. Je voudrais me préparer pour le Concourt)");
    } else {
      setplaceholder2("Votre Adresse");
    }

    if (placeholder3 === "(  ...)") {
      setplaceholder3("...");
    } else {
      setplaceholder3("...");
    }
  };

  const onClickChangerFormulaire = () => {
    // Changer la valeur du formulaire ici
    setFormulaire(()=> !Formulaire);
  };

  return ( <>
   
    <div>
      <div className="ficheAdmin">
        
        <div className="ficheAdmin_haut">
          {authState.username === username  &&  (
            <>
             <button onClick={onClickChangerFormulaire}>Formulaire</button>
         <button
                className=""
                onClick={() => {
                  history.push("/changepasswordreq");
                }}
              >
                {" "}
                Changer mon mots de passe
              </button>
              
              <div
                className="photo"
                onClick={() => {
                  setinputphoto((inputphoto) => !inputphoto);
                }}
              >

                <img src={image} />
              </div>{" "}
              {inputphoto && (
                <>
                  <form onSubmit={Submit}>
                    <input
                      onChange={fileSelected}
                      type="file"
                      accept="image/*"
                      id="image"
                      required
                    />
                    {isSuccess ? ( <>
                      <button type="submit">Submit</button>
                     
                      </>
                    ) : (
                      <div className="fichier"> "Veuillez selectionner un fichier de moins de 800 ko ce fichier fait :  {tailledufichier / 1000} ko"</div>
                    )}
                   
                  </form>
                </>
              )}
            </>
          )}
        </div>

        <div className="administration">
         
            <>
           

       

            { Formulaire &&   <div className="createPostPage">
             
                <Formik 
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  
                  <Form className="formContainer2">
                  
                      {checked && (
                      <div  className="titleform"> <label> Créer une fiche de Renseignements </label> </div>
                      )}

                      {!checked && <div  className="titleform">
                         <label> Créer une fiche de suivi </label>
                         </div>
                         }
                   
{prof &&       <select
    value={Identifiant}
    onChange={(f) => setIdentifiant(f.target.value)} 
    required
  >
    <option value="" disabled>
      Sélectionnez un utilisateur
    </option>
    {ListOfUsers.map((user) => (
      <option key={user.id} value={user.id} onChange={() => setIdentifiant(user.id)}>
        {user.username} : {user.email}
      </option>
    ))}
  </select>}






                    {checked && <label>Votre numéro de téléphone : </label>}
                    {!checked && <label>Titre da la fiche : </label>}
                    <ErrorMessage name="title" component="span" />
                    <Field
                      autoComplete="off"
                      id="inputCreatePost"
                      name="title"
                      placeholder={placeholder}
                    />
                    {checked && <label>Votre Adresse : </label>}
                    {!checked && <label>Déscription : </label>}
                    <ErrorMessage name="postText" component="span" />
                    <Field
                      cols="45"
                      rows="3"
                      component="textarea"
                      autoComplete="off"
                      id="postText"
                      name="postText"
                      placeholder={placeholder2}
                      type="text"
                    />
                    <label>
                      {checked && (
                        <label>Quels sont vos jours de disponibilités ?</label>
                      )}
                      {!checked && (
                        <label>
                         Expliquez plus en détails
                        </label>
                      )}
                    </label>
                    <ErrorMessage name="lien" component="span" />
                    <Field
                      type="text"
                      autoComplete="off"
                      id="lien"
                      name="lien"
                      placeholder={placeholder3}
                    />
             {!FicheExiste &&       <div>
                     <div>Créer une fiche de Renseignements</div> 
                      <input
                        id="renseignement"
                        name="renseignement"
                        value={checked}
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                      />{" "}
                    </div>}
                    <button type="submit"> Créer une fiche    {checked && <div>de Renseignements</div>}</button>
                  </Form>
                </Formik>
              </div>

             }


            </>
         


   <div className="indivi">
  <div className="renseignement">Ma Fiche de Renseignements</div>
         { FicheExiste &&  <div className="post2">
           <div
                className="title"
                onClick={() => {
                  if (authState.username === postObject.username) {
                    editPost("title");
                  }
                }}
              >
               📱 { postObject.title}
              </div>

         <div
                className="body"
                onClick={() => {
                  if (authState.username === postObject.username) {
                    editPost("body");
                  }
                }}
              >
               📍 { postObject.postText}
              </div>
           <p
                className="lien2"
                onClick={() => {
                  if (authState.username === postObject.username) {
                    editPost("lien");
                  }
                }}
              >
                <span className="fond"> 📆 Jours de disponibilité: { postObject.lien}</span>
              </p>
              {(authState.username === postObject.username ||
                  authState.admin === true) && (
                  <button className="boutonsupr"
                    onClick={() => {
                      deletePost(postObject.id);
                    }}
                  >
                   
                    Supprimer la fiche
                  </button>
                )}

      <div className="footer">
          
              </div>
            </div>}
{  FicheExiste    &&       <p>
              Cliquer sur le titre de la fiche pour modifier le titre ou sur le
              texte pour modifier le text
            </p>}

            {  !FicheExiste    &&       <p>
              Servez-vous du formulaire et cocher la case créer une fiche de renseignement pour créer une fiche de renseignement
            </p>}
            <div className="lien">
              <NavLink to={`/`}>
                Vers la liste de mes fiches{" "}
              </NavLink>
            </div>
           <div className="Booknumber"><Booknumber /></div> 
          </div>

        </div>
      </div>
    </div> 
     </>
  );
}
export default FicheAdmin;
