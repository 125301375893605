import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { apiUrl } from "../config";
import { AuthContext } from "../helpers/AuthContext";

function Booknumber() {
  const [selectedBook, setSelectedBook] = useState(null);
  const [books, setBooks] = useState([]);
  const { authState } = useContext(AuthContext); 

  useEffect(() => {
    // Récupérer les données des livres depuis l'API
    axios.get(`${apiUrl}/postimages/lireAllimagespresentation/${authState.id}`, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    })
    .then((response) => {
      setBooks(response.data); // Mettre à jour la liste des livres
    })
    .catch((error) => {
      console.error('Erreur lors du chargement des livres : ', error);
    });

    const displayedBook = localStorage.getItem('mybook');
    if (displayedBook) {
      setSelectedBook(displayedBook);
    }
  }, [authState.id]);

  const getSelectedBookTitle = () => {
    if (selectedBook) {
      const bookIndex = parseInt(selectedBook) - 1; // L'index du livre dans la liste est égal à son numéro moins 1
      const book = books[bookIndex];
      const bookTitle = book ? (book.title ? book.title : "Sans titre") : "Sans titre";
      return `${bookTitle} - Votre livre n° ${selectedBook}`;
    } else {
      return "Pas de livre choisi";
    }
  };
  

  const handleBookSelection = (event) => {
    const bookId = parseInt(event.target.value);
    setSelectedBook(bookId);
    localStorage.setItem('mybook', bookId); // Enregistrer l'ID du livre sélectionné
    localStorage.setItem('myid', authState.id); // Enregistrer l'ID de l'utilisateur
  };

  return (
    <div>
      <span className='livrenumber'>--Le livre sera afficher sur ce navigateur-- </span>
      {/* Liste déroulante pour choisir le livre */}
      <select onChange={handleBookSelection}>
        <option value="">Choisir le livre que vous voudrez voir apparaitre sur l'écran d'accueil</option>
        {books.map((book, index) => (
          <option key={book.id} value={index + 1}>
            {book.title ? book.title : "Sans titre"} - Livre {index + 1}
          </option>
        ))}
      </select>
      {/* Afficher le livre sélectionné */}
      <p>Le livre sélectionné pour s'afficher en première page est : {getSelectedBookTitle()}</p>
    
    </div>
  );
}

export default Booknumber;
